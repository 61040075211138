// general:
export const SET_COUNTS = "SET_COUNTS";
export const SET_CATEGORY_COUNTS = "SET_CATEGORY_COUNTS";

// articles:
export const ARTICLE_ERROR = "ADD_ARTICLE_ERROR";
export const ADD_ARTICLE = "ADD_ARTICLE";
export const SET_ARTICLES = "SET_ARTICLES";
export const EDIT_ARTICLE = "EDIT_ARTICLE";
export const DELETE_ARTICLE = "DELETE_ARTICLE";
export const FETCH_ONE_ARTICLE = "FETCH_ONE_ARTICLE";
export const SET_CURRENT_ARTICLE = "SET_CURRENT_ARTICLE";
export const SET_TOURNAMENT_ARTICLES = "SET_TOURNAMENT_ARTICLES";
export const SET_TOP_TEN = "SET_TOP_TEN";
export const SET_FREE_DAILIES = "SET_FREE_DAILIES";

// category articles:
export const CATEGORY_ARTICLE_ERROR = "ADD_ARTICLE_ERROR";
export const CATEGORY_ADD_ARTICLE = "CATEGORY_ADD_ARTICLE";
export const CATEGORY_SET_ARTICLES = "CATEGORY_SET_ARTICLES";
export const CATEGORY_EDIT_ARTICLE = "CATEGORY_EDIT_ARTICLE";
export const CATEGORY_DELETE_ARTICLE = "CATEGORY_DELETE_ARTICLE";
export const CATEGORY_FETCH_ONE_ARTICLE = "CATEGORY_FETCH_ONE_ARTICLE";
export const SET_CURRENT_CATEGORY_ARTICLE = "SET_CURRENT_CATEGORY_ARTICLE";

// quizzes:
export const ADD_QUIZ = "ADD_QUIZ";
export const SET_QUIZZES = "SET_QUIZZES";
export const REMOVE_QUIZ = "REMOVE_QUIZ";
export const EDIT_QUIZ = "EDIT_QUIZ";

// single quiz:
export const FETCH_ONE_QUIZ = "FETCH_ONE_QUIZ";
export const SET_CURRENT_QUIZ = "SET_CURRENT_QUIZ";

// users:
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_SET = "USER_SET";

export const SET_USER_QUIZ_SCORES = "SET_USER_QUIZ_SCORES";
export const SET_USER_CURRENT_QUIZ = "SET_USER_CURRENT_QUIZ";
export const USER_SET_SUBSCRIPTION_EXPIRES = "USER_SET_SUBSCRIPTION_EXPIRES";
export const USER_CHANGE_SUBSCRIPTION_ACTIVE_STATUS =
  "USER_CHANGE_SUBSCRIPTION_ACTIVE_STATUS";

// filters:
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_CATEGORY_FILTER = "SET_CATEGORY_FILTER";
export const SET_DIFFICULTY_FILTER = "SET_DIFFICULTY_FILTER";
export const UPDATE_SEARCH_STRING = "UPDATE_SEARCH_STRING";
export const SET_HIDE_COMPLETED_QUIZZES_FILTER =
  "SET_HIDE_COMPLETED_QUIZZES_FILTER";
export const SET_FILTER_TYPE = "SET_FILTER_TYPE";

import React from "react";
import "./PrivacyPolicy.css";
import $ from "jquery";

class PrivacyPolicy extends React.Component {
  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 800);
  }

  render() {
    return (
      <div className="PrivacyPolicy-container">
        <h3>Privacy Policy</h3>
        <br />
        <br />
        <p>
          This privacy policy has been compiled to better serve those who are
          concerned with how their 'Personally Identifiable Information' (PII)
          is being used online. PII, as described in US privacy law and
          information security, is information that can be used on its own or
          with other information to identify, contact, or locate a single
          person, or to identify an individual in context. Please read our
          privacy policy carefully to get a clear understanding of how we
          collect, use, protect or otherwise handle your Personally Identifiable
          Information in accordance with our website.
        </p>
        <br />
        <br />
        <p>
          <strong>
            What personal information do we collect from the people that visit
            our blog, website or app?
          </strong>
        </p>
        <br />
        <p>
          When ordering or registering on our site, as appropriate, you may be
          asked to enter your name, email address, mailing address, phone number
          or other details to help you with your experience.
        </p>
        <br />
        <br />
        <p>
          <strong>When do we collect information?</strong>
        </p>
        <br />
        <p>
          We collect information from you when you register on our site, place
          an order, subscribe to a newsletter, respond to a survey, fill out a
          form, Use Live Chat, Open a Support Ticket or enter information on our
          site.
        </p>

        <br />
        <br />
        <p>
          <strong>Request Deletion of Your Data</strong>
        </p>
        <br />
        <p>
          Users may request deletion of that data by going to their profile and
          clicking the "Clear Account Profile Data" button.
        </p>

        <p>
          Provide us with feedback on our products or services
          <br />
          <br />
          <strong>How do we use your information?</strong>
        </p>
        <br />
        <p>
          We may use the information we collect from you when you register, make
          a purchase, sign up for our newsletter, respond to a survey or
          marketing communication, surf the website, or use certain other site
          features in the following ways:
        </p>
        <p>
          <ul>
            <li>
              {" "}
              • To personalize your experience and to allow us to deliver the
              type of content and product offerings in which you are most
              interested.
            </li>
            <li> • To improve our website in order to better serve you.</li>
            <li>
              {" "}
              • To allow us to better service you in responding to your customer
              service requests.
            </li>
            <li>
              {" "}
              • To administer a contest, promotion, survey or other site
              feature.
            </li>
            <li> • To quickly process your transactions.</li>
            <li> • To ask for ratings and reviews of services or products</li>
            <li>
              {" "}
              • To follow up with them after correspondence (live chat, email or
              phone inquiries)
            </li>
          </ul>
        </p>
        <br />
        <br />
        <p>
          <strong>How do we protect your information?</strong>
        </p>
        <br />
        <p>
          We do not use vulnerability scanning and/or scanning to PCI standards.
        </p>
        <p>
          An external PCI compliant payment gateway handles all CC transactions.
        </p>

        <p>
          Your personal information is contained behind secured networks and is
          only accessible by a limited number of persons who have special access
          rights to such systems, and are required to keep the information
          confidential. In addition, all sensitive/credit information you supply
          is encrypted via Secure Socket Layer (SSL) technology.
        </p>

        <p>
          We implement a variety of security measures when a user places an
          order enters, submits, or accesses their information to maintain the
          safety of your personal information.
        </p>

        <p>
          All transactions are processed through a gateway provider and are not
          stored or processed on our servers.
        </p>
        <br />
        <br />
        <p>
          <strong>Do we use 'cookies'?</strong>
        </p>
        <br />
        <p>
          Yes. Cookies are small files that a site or its service provider
          transfers to your computer's hard drive through your Web browser (if
          you allow) that enables the site's or service provider's systems to
          recognize your browser and capture and remember certain information.
          For instance, we use cookies to help us remember and process the items
          in your shopping cart. They are also used to help us understand your
          preferences based on previous or current site activity, which enables
          us to provide you with improved services. We also use cookies to help
          us compile aggregate data about site traffic and site interaction so
          that we can offer better site experiences and tools in the future.
        </p>

        <p>
          <strong>We use cookies to:</strong>• Understand and save user's
          preferences for future visits. • Keep track of advertisements. •
          Compile aggregate data about site traffic and site interactions in
          order to offer better site experiences and tools in the future. We may
          also use trusted third-party services that track this information on
          our behalf.
        </p>

        <p>
          You can choose to have your computer warn you each time a cookie is
          being sent, or you can choose to turn off all cookies. You do this
          through your browser settings. Since browser is a little different,
          look at your browser's Help Menu to learn the correct way to modify
          your cookies.
        </p>

        <p>
          {" "}
          If you turn cookies off, Some of the features that make your site
          experience more efficient may not function properly.It won't affect
          the user's experience that make your site experience more efficient
          and may not function properly.
        </p>

        <br />
        <br />
        <p>
          <strong>Third-party disclosure</strong>
        </p>
        <br />
        <p>
          We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information unless we provide users with
          advance notice. This does not include website hosting partners and
          other parties who assist us in operating our website, conducting our
          business, or serving our users, so long as those parties agree to keep
          this information confidential. We may also release information when
          it's release is appropriate to comply with the law, enforce our site
          policies, or protect ours or others' rights, property or safety.
        </p>

        <p>
          However, non-personally identifiable visitor information may be
          provided to other parties for marketing, advertising, or other uses.
        </p>
        <br />
        <br />
        <p>
          <strong>Third-party links</strong>
        </p>
        <br />

        <p>
          Occasionally, at our discretion, we may include or offer third-party
          products or services on our website. These third-party sites have
          separate and independent privacy policies. We therefore have no
          responsibility or liability for the content and activities of these
          linked sites. Nonetheless, we seek to protect the integrity of our
          site and welcome any feedback about these sites.
        </p>
        <br />
        <br />
        <p>
          <strong>Google</strong>
        </p>
        <br />
        <p>
          Google's advertising requirements can be summed up by Google's
          Advertising Principles. They are put in place to provide a positive
          experience for users.
        </p>
        <p>We use Google AdSense Advertising on our website.</p>

        <p>
          Google, as a third-party vendor, uses cookies to serve ads on our
          site. Google's use of the DART cookie enables it to serve ads to our
          users based on previous visits to our site and other sites on the
          Internet. Users may opt-out of the use of the DART cookie by visiting
          the Google Ad and Content Network privacy policy.
        </p>
        <br />
        <br />
        <p>
          <strong>We have implemented the following:</strong>
        </p>
        <br />
        <p>
          • Remarketing with Google AdSense
          <br />
          • Google Display Network Impression Reporting
          <br />
          • Demographics and Interests Reporting
          <br />
          • DoubleClick Platform Integration
          <br />
        </p>

        <p>
          We, along with third-party vendors such as Google use first-party
          cookies (such as the Google Analytics cookies) and third-party cookies
          (such as the DoubleClick cookie) or other third-party identifiers
          together to compile data regarding user interactions with ad
          impressions and other ad service functions as they relate to our
          website.
        </p>
        <br />
        <br />
        <p>
          <strong>Opting out:</strong>
        </p>
        <br />
        <p>
          Users can set preferences for how Google advertises to you using the
          Google Ad Settings page. Alternatively, you can opt out by visiting
          the Network Advertising Initiative Opt Out page or by using the Google
          Analytics Opt Out Browser add on.
        </p>
        <br />
        <br />
        <p>
          <strong>California Online Privacy Protection Act</strong>
        </p>
        <br />
        <p>
          CalOPPA is the first state law in the nation to require commercial
          websites and online services to post a privacy policy. The law's reach
          stretches well beyond California to require any person or company in
          the United States (and conceivably the world) that operates websites
          collecting Personally Identifiable Information from California
          consumers to post a conspicuous privacy policy on its website stating
          exactly the information being collected and those individuals or
          companies with whom it is being shared. - See more at:
          http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf
        </p>

        <br />
        <br />
        <p>
          <strong>According to CalOPPA, we agree to the following:</strong>
        </p>
        <br />
        <p>
          Users can visit our site anonymously. Once this privacy policy is
          created, we will add a link to it on our home page or as a minimum, on
          the first significant page after entering our website. Our Privacy
          Policy link includes the word 'Privacy' and can easily be found on the
          page specified above.
        </p>

        <p>
          You will be notified of any Privacy Policy changes on our Privacy
          Policy Page
          <br />
          You may modify your user profile information when that functionality
          becomes available.
        </p>
        <br />
        <br />
        <p>
          <strong>COPPA (Children Online Privacy Protection Act)</strong>
        </p>
        <br />
        <p>
          When it comes to the collection of personal information from children
          under the age of 13 years old, the Children's Online Privacy
          Protection Act (COPPA) puts parents in control. The Federal Trade
          Commission, United States' consumer protection agency, enforces the
          COPPA Rule, which spells out what operators of websites and online
          services must do to protect children's privacy and safety online.
        </p>

        <p>
          We do not specifically market to children under the age of 13 years
          old.
        </p>

        <br />
        <br />
        <p>
          <strong>Fair Information Practices</strong>
        </p>
        <br />
        <p>
          The Fair Information Practices Principles form the backbone of privacy
          law in the United States and the concepts they include have played a
          significant role in the development of data protection laws around the
          globe. Understanding the Fair Information Practice Principles and how
          they should be implemented is critical to comply with the various
          privacy laws that protect personal information.
        </p>
        <br />
        <br />
        <p>
          <strong>
            In order to be in line with Fair Information Practices we will take
            the following responsive action, should a data breach occur:
          </strong>
        </p>
        <br />
        <p>
          We will notify you via email within 7 business days of becoming aware
          of the breach.
          <br />
        </p>
      </div>
    );
  }
}
export default PrivacyPolicy;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-materialize';

// import './Videos.css';

class Videos extends Component {
    // title: this.state.title,
    // category: this.state.category,
    // difficulty: this.state.difficulty,
    // teaser: this.state.teaser,
    // createdAt: ...
    componentDidMount() {

    }

    render() {
        return (
            <div className="">

                Hello from Videos
            </div>
        );
    }
}

export default Videos;